import { Boss, LeagueMechanic, MapFeature, MasterMission } from "./poe-types";

export enum NodeType {
  Keystone = "keystone",
  Notable = "notable",
  SmallPassive = "small-passive",
  Wormhole = "wormhole",
}

export enum NodeGrouping {
  UberBossKeystone = "Ubers",
  MappingKeystone = "Keystones",
  Notable = "Notables",
  Gateway = "Gateways",
  ClusterSmallPassive = "Small nodes",
  TravelSmallPassive = "Travel",
}

export enum ClusterGrouping {
  LeagueMechanic = "League mechanics",
  MasterMission = "Master missions",
  MapFeature = "Map features",
  Boss = "Boss content",
}

export type ClusterType = LeagueMechanic | MasterMission | MapFeature | Boss;

export interface TreeNode {
  id: number;
  name: string;
  type: NodeType;
  stats: string[];
  grouping: NodeGrouping;
  cluster?: Cluster;
}

export interface Cluster {
  id: number;
  name: string;
  grouping: ClusterGrouping;
  type: ClusterType;
}

export interface AtlasTree {
  allocatedPoints: number;
  nodes: TreeNode[];
}
