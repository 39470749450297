import { PieChart as MinimalPieChart } from "react-minimal-pie-chart";
import { PieChartProps, getChartData } from "./types";

const PieChart = (props: PieChartProps) => {
  const data = getChartData(props.breakdown);

  return (
    <div className=" w-full h-full flex flex-col text-center">
      <div className="text-blue-200 text-4xl">
        {props.breakdown.displayName}
      </div>
      <MinimalPieChart
        data={data}
        startAngle={220}
        segmentsShift={props.shift ? 2 : 0}
        animate
        radius={props.radius ?? 25}
        style={{ height: "85%", overflow: "visible" }}
        label={
          props.customLabel ??
          (({ dataEntry }) => `${dataEntry.title}: ${dataEntry.value}`)
        }
        labelPosition={115}
        labelStyle={(idx) => ({
          fontSize: "4.5px",
          fill: data[idx].labelColor,
        })}
      ></MinimalPieChart>
    </div>
  );
};

export default PieChart;
