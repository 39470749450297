import PieChart from "../charts/PieChart";
import { AtlasTree, ClusterGrouping } from "tree-helpers/types";
import { buildBreakdown } from "node-groupings/builder";
import CorePointsPanel from "./CorePointsPanel";

export type BuildStatsProps = {
  build: AtlasTree;
};

const BuildStats = (props: BuildStatsProps) => {
  const { build } = props;

  const preconfiguredBreakdowns = {
    allPointsByType: buildBreakdown(
      build,
      "Points by node type",
      (node) => node?.grouping ?? "n/a",
      undefined,
    ),
    nonTravelPointsByClusterGrouping: buildBreakdown(
      build,
      "Cluster focus",
      (_, cluster) => cluster?.grouping ?? "n/a",
      {
        cluster: () => true,
      },
    ),
    // more drilldown-y breakdowns
    byLeague: buildBreakdown(
      build,
      "League mechanics",
      (_, cluster) => cluster?.type ?? "n/a",
      {
        cluster: (cluster) =>
          cluster.grouping === ClusterGrouping.LeagueMechanic,
      },
    ),
    byMaster: buildBreakdown(
      build,
      "Master missions",
      (_, cluster) => cluster?.type ?? "n/a",
      {
        cluster: (cluster) =>
          cluster.grouping === ClusterGrouping.MasterMission,
      },
    ),
    byBoss: buildBreakdown(
      build,
      "Boss content",
      (_, cluster) => cluster?.type ?? "n/a",
      {
        cluster: (cluster) => cluster.grouping === ClusterGrouping.Boss,
      },
    ),
    byMapFeature: buildBreakdown(
      build,
      "Map features",
      (_, cluster) => cluster?.type ?? "n/a",
      {
        cluster: (cluster) => cluster.grouping === ClusterGrouping.MapFeature,
      },
    ),
  };

  const minorBreakdowns = [
    preconfiguredBreakdowns.byMapFeature,
    preconfiguredBreakdowns.byMaster,
    preconfiguredBreakdowns.byLeague,
    preconfiguredBreakdowns.byBoss,
  ].filter((breakdown) => breakdown.totalPoints > 0);

  const calculatePieRadius = (breakdownIdx: number) => {
    const relativeMinorWeights = minorBreakdowns.map(
      (breakdown) => breakdown.totalPoints,
    );
    const minorPointsSum = relativeMinorWeights.reduce((a, b) => a + b);
    const baseRadius = 30;
    const relativeWeight =
      (relativeMinorWeights[breakdownIdx] * 1.0) / minorPointsSum;
    const signedWeight = 0.5 - relativeWeight;
    const forceMultiplier = 20;
    return baseRadius - forceMultiplier * signedWeight;
  };

  return (
    <div className="flex-1 flex flex-col w-4/5 self-center gap-12 mt-4">
      <div className="h-128 flex gap-16 justify-evenly">
        <div className="flex-1">
          <PieChart
            breakdown={preconfiguredBreakdowns.allPointsByType}
            shift
          ></PieChart>
        </div>
        <div className="flex-1">
          <CorePointsPanel build={build}></CorePointsPanel>
        </div>
        <div className="flex-1">
          <PieChart
            breakdown={preconfiguredBreakdowns.nonTravelPointsByClusterGrouping}
            shift
          ></PieChart>
        </div>
      </div>
      <div className="h-128 flex gap-2 justify-evenly">
        {minorBreakdowns.map((breakdown, idx) => (
          <div className="flex-1" key={`minor-piechart-${idx}`}>
            <PieChart
              breakdown={breakdown}
              radius={calculatePieRadius(idx)}
            ></PieChart>
          </div>
        ))}
      </div>
    </div>
  );
};

export default BuildStats;
