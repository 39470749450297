import { getNodeById } from "./node-lookups";
import { AtlasTree } from "./types";

enum treeSlugSource {
  default,
  poePlanner,
}

export const normalizeTreeSlug = (urlOrSlug: string): string => {
  const split = urlOrSlug.split("/");

  const path = split[split.length - 1];

  return path;
};

const decodeTreeSlug = (slug: string, source: treeSlugSource): AtlasTree => {
  const slugWithReplacements = slug.replaceAll("-", "+").replaceAll("_", "/");
  const buffer = Buffer.from(
    decodeURIComponent(slugWithReplacements),
    "base64",
  );

  const nodeIds: number[] = [];
  let encodedBuildLength = 0;

  if (source === treeSlugSource.default) {
    const headerLength = 7;
    const buildLengthOffset = 6;
    const MAGIC_END = 2;

    encodedBuildLength = buffer.readUInt8(buildLengthOffset);

    for (let idx = headerLength; idx < buffer.length - MAGIC_END; idx += 2) {
      nodeIds.push(buffer.readUInt16BE(idx));
    }
  } else if (source === treeSlugSource.poePlanner) {
    const headerLength = 6;
    const buildLengthOffset = 4;

    encodedBuildLength = buffer.readUInt16LE(buildLengthOffset);

    for (
      let idx = headerLength;
      idx < headerLength + encodedBuildLength * 2;
      idx += 2
    ) {
      nodeIds.push(buffer.readUInt16LE(idx));
    }
  }

  if (encodedBuildLength !== nodeIds.length) {
    throw new Error(
      "Encoded amount of node doesn't match build length" +
        ` (encoded: ${encodedBuildLength}, read: ${nodeIds.length})`,
    );
  }

  return {
    allocatedPoints: nodeIds.length,
    nodes: nodeIds.map((nodeId) => getNodeById(nodeId)),
  };
};

export const parseTreeUrlOrSlug = (
  urlOrSlug: string,
): { tree: AtlasTree | null; ok: boolean; errorMessage: string | null } => {
  let treeResult = null;
  let ok = true;
  let errorMessage: string | null = null;
  let source = treeSlugSource.default;

  try {
    if (urlOrSlug.toLowerCase().includes("poeplanner")) {
      source = treeSlugSource.poePlanner;
    }

    const slug = normalizeTreeSlug(urlOrSlug);

    // kinda shitty way to do it but, for now..
    if (source === treeSlugSource.default && slug.startsWith("BAA")) {
      source = treeSlugSource.poePlanner;
    }
    if (source === treeSlugSource.default && !slug.startsWith("AAAABgAA")) {
      throw new Error("Invalid tree slug (doesn't start with correct header)");
    }
    treeResult = decodeTreeSlug(slug, source);
  } catch (error: unknown) {
    if (typeof error === "string") {
      errorMessage = error;
    } else if (error instanceof Error) {
      errorMessage = error.message;
    } else {
      errorMessage = "Unknown error";
    }
    ok = false;
  }

  return { tree: treeResult, ok, errorMessage };
};
