import Home from "./pages/Home";
import Build from "./pages/Build";
import DebugClusterMappings from "./pages/debug/ClusterMappings";

import { Routes, Route } from "react-router-dom";

const App = () => {
  return (
    <div className="w-full min-h-screen bg-ancestor bg-top bg-no-repeat bg-cover bg-fixed	text-gray-300 text-xl">
      {/* re-enable if background is too light and results in not enough contrast */}
      {/* <div className="min-h-screen bg-black bg-opacity-30 flex flex-col pb-12"> */}
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path=":build" element={<Build />} />
          <Route
            path="/cluster-mappings"
            element={<DebugClusterMappings />}
          ></Route>
        </Routes>
      {/* </div> */}
    </div>
  );
};

export default App;
