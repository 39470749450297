import treeData from "../../skill-tree-data/data.json";
import {
  getAllClusterMappings,
  getClusterMapping,
  clusterMapping,
} from "../../tree-helpers/cluster-mappings";
import {
  LeagueMechanic,
  MapFeature,
  MasterMission,
  TravelNodeGrouping,
} from "../../tree-helpers/poe-types";
import Navbar from "../../components/Navbar";
import { NodeGrouping } from "../../tree-helpers/types";
import {
  getNodeGroupingFromNodeData,
  nodeJSONFormat,
} from "../../tree-helpers/node-lookups";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";

const DebugClusterMappings = () => {
  const nodesByUniqueGroups: nodeJSONFormat[] = [];
  const seenGroups: number[] = [];

  const travelNodeNames = Object.values(TravelNodeGrouping);
  const travelNodeDescriptions: string[] = [];

  const [selectedGroupIds, setSelectedGroupIds] = React.useState<number[]>([]);

  const toggleSelected = (groupId: number) => {
    if (selectedGroupIds.includes(groupId)) {
      setSelectedGroupIds(selectedGroupIds.filter((item) => item !== groupId));
      return;
    }

    setSelectedGroupIds(selectedGroupIds.concat([groupId]));
  };

  [
    (node: nodeJSONFormat) => node.isNotable,
    (node: nodeJSONFormat) => {
      if (!node.group) {
        return false;
      }

      if (node.isMastery || node.isWormhole) {
        return false;
      }

      if (!node.name) {
        return false;
      }

      const grouping = getNodeGroupingFromNodeData(node);
      return (
        grouping !== NodeGrouping.MappingKeystone &&
        grouping !== NodeGrouping.TravelSmallPassive
      );
    },
  ].map((predicate) => {
    Object.getOwnPropertyNames(treeData.nodes).forEach((key) => {
      const treeDataNode = treeData.nodes[key];
      if (seenGroups.includes(treeDataNode.group)) {
        return;
      }

      if (predicate(treeDataNode)) {
        seenGroups.push(treeDataNode.group);
        nodesByUniqueGroups.push(treeDataNode);
      }
    });
  });

  Object.getOwnPropertyNames(treeData.nodes).forEach((key) => {
    const node = treeData.nodes[key];
    if (!travelNodeNames.includes(node.name)) {
      return;
    }

    if (!node.stats) {
      console.log(node);
      return;
    }

    if (travelNodeDescriptions.includes(JSON.stringify(node.stats))) {
      return;
    }
    travelNodeDescriptions.push(JSON.stringify(node.stats));
  });

  type duplicateGroupIdInfo = {
    groupId: number;
    mappings: clusterMapping[];
    nodesWithGroupId: nodeJSONFormat[];
  };

  const duplicateGroupIdInfos: duplicateGroupIdInfo[] = [];
  const duplicateGroupIds: number[] = [];
  const seenGroupIds: number[] = [];
  getAllClusterMappings().forEach((mapping) => {
    mapping.groupIds.forEach((groupId) => {
      if (
        seenGroupIds.includes(groupId) &&
        !duplicateGroupIds.includes(groupId)
      ) {
        duplicateGroupIds.push(groupId);
        duplicateGroupIdInfos.push({
          groupId,
          mappings: getAllClusterMappings().filter((mapping) =>
            mapping.groupIds.includes(groupId),
          ),
          nodesWithGroupId: Object.getOwnPropertyNames(treeData.nodes)
            .map((key) => treeData.nodes[key])
            .filter((node: nodeJSONFormat) => node.group === groupId),
        });
      }

      if (!seenGroupIds.includes(groupId)) {
        seenGroupIds.push(groupId);
      }
    });
  });

  const groupIdsWithoutNodes = seenGroupIds.filter(
    (groupId) =>
      Object.getOwnPropertyNames(treeData.nodes)
        .map((key) => treeData.nodes[key])
        .filter((node: nodeJSONFormat) => node.group === groupId).length === 0,
  );

  const copyText = JSON.stringify(
    selectedGroupIds.sort((a, b) => a - b),
    null,
    2,
  );

  return (
    <>
      <Navbar></Navbar>
      <div style={{ display: "block", width: "80%" }}>
        {selectedGroupIds.length > 0 && (
          <div
            style={{
              border: "8px solid #f836ff",
              fontSize: "22pt",
              margin: "12px",
              padding: "12px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <CopyToClipboard text={copyText}>
              <button className="m-8 self-center rounded-lg border-4 border-blue-400 p-4 text-5xl">
                {copyText}
              </button>
            </CopyToClipboard>

            <button
              className="text-red-400 self-center rounded-md border-2 p-4 border-red-600"
              onClick={() => setSelectedGroupIds([])}
            >
              Clear selected nodes
            </button>
          </div>
        )}
        {groupIdsWithoutNodes.length > 0 && (
          <div
            style={{
              border: "1px solid red",
              fontSize: "22pt",
              margin: "12px",
              padding: "12px",
              display: "inline-flex",
            }}
          >
            Old, unused group IDs found: {groupIdsWithoutNodes.join(", ")}
          </div>
        )}
        {duplicateGroupIds.length > 0 && (
          <div
            style={{
              border: "1px solid red",
              fontSize: "22pt",
              margin: "12px",
              padding: "12px",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div style={{ margin: "6px" }}>Duplicate group mappings exist!</div>
            {duplicateGroupIdInfos.map((duplicateGroup) => {
              return (
                <div
                  key={`dup-group-${duplicateGroup.groupId}`}
                  style={{
                    border: "1px solid grey",
                    fontSize: "16pt",
                    margin: "6px",
                    padding: "6px",
                  }}
                >
                  <div>
                    <b>Group ID:</b> {duplicateGroup.groupId}
                  </div>
                  <div>
                    <b>Appears in:</b>{" "}
                    {duplicateGroup.mappings
                      .map(
                        (mapping) => `${mapping.grouping} -> ${mapping.type}`,
                      )
                      .join(", ")}
                  </div>
                  <div>
                    <b>Tree nodes with it:</b>{" "}
                    {duplicateGroup.nodesWithGroupId
                      .filter(
                        (node) =>
                          !travelNodeNames
                            .map((name) => name.toString())
                            .includes(node.name),
                      )
                      .map((node) => node.name)
                      .join(", ")}
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {travelNodeDescriptions.map((desc, idx) => {
          return (
            <div
              key={`${idx}-div-desc`}
              style={{
                border: "1px solid blue",
                fontSize: "16pt",
                margin: "12px",
                padding: "12px",
                display: "inline-flex",
              }}
            >
              {desc}
            </div>
          );
        })}
        {nodesByUniqueGroups
          .sort((nodeA, nodeB) => nodeA.group - nodeB.group)
          .map((node, idx) => {
            let mapping = null;
            try {
              mapping = getClusterMapping(node.group);
            } catch {
              console.log(`Found no cluster mapping for groupId ${node.group}`);
            }

            const mappingType = mapping !== null ? mapping.type : "";
            let kindaSus = false;
            if (
              mapping !== null &&
              mapping.type === LeagueMechanic.ExcludedLeagueContent &&
              (!node.stats ||
                node.stats.length === 0 ||
                !node.stats[0].includes("Your Maps have no chance to contain"))
            ) {
              kindaSus = true;
            } else if (
              mappingType !== "" &&
              mapping !== null &&
              mapping.type !== LeagueMechanic.ExcludedLeagueContent &&
              (!node.stats ||
                node.stats.length === 0 ||
                node.stats.filter((stat) =>
                  stat
                    .toLowerCase()
                    // strip trailing "s" or "es" for mechanics like "Strongboxes" to match "Strongbox"
                    .includes(mappingType.toLowerCase().replace(/(s|es)$/, "")),
                ).length === 0)
            ) {
              kindaSus = true;
            }

            if (
              mapping !== null &&
              mapping.type === MasterMission.Bestiary &&
              (!node.stats ||
                node.stats.length === 0 ||
                node.stats.filter(
                  (stat) =>
                    stat.toLowerCase().includes("Red Beasts".toLowerCase()) ||
                    stat.toLowerCase().includes("Einhar".toLowerCase()),
                ).length !== 0)
            ) {
              kindaSus = false;
            }

            if (
              mapping !== null &&
              mapping.type === MapFeature.TormentedSpirits &&
              (!node.stats ||
                node.stats.length === 0 ||
                node.stats.filter(
                  (stat) =>
                    stat.toLowerCase().includes("Possessed".toLowerCase()) ||
                    stat.toLowerCase().includes("Touched".toLowerCase()),
                ).length !== 0)
            ) {
              kindaSus = false;
            }

            const selected = selectedGroupIds.includes(node.group);

            return (
              <div
                style={{
                  border: selected
                    ? `1px dashed #f836ff`
                    : `1px solid ${
                        mapping ? (kindaSus ? "orange" : "green") : "red"
                      }`,
                  fontSize: "16pt",
                  margin: "12px",
                  padding: "12px",
                  display: "inline-flex",
                }}
                key={`${idx}-div`}
                onClick={() => toggleSelected(node.group)}
              >
                Node name: {node.name}
                <br />
                Node stats: {node.stats ? node.stats.join(", ") : "n/a"}
                <br />
                Group ID: {node.group}
                <br />
                Mapping found:{" "}
                {mapping ? `Yes, ${mapping.grouping} -> ${mapping.type}` : "No"}
                <br />
              </div>
            );
          })}
      </div>
    </>
  );
};

export default DebugClusterMappings;
