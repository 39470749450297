import { Boss, LeagueMechanic, MapFeature, MasterMission } from "./poe-types";
import { ClusterGrouping, ClusterType } from "./types";

export interface clusterMapping {
  type: ClusterType;
  grouping: ClusterGrouping;
  groupIds: number[];
}

const clusterMappings: clusterMapping[] = [
  // League mechanics
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Beyond,
    groupIds: [6, 18, 19, 41],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Blight,
    groupIds: [9, 43, 45, 48, 52, 89],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Delirium,
    groupIds: [30, 34, 58, 64, 94],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Metamorph,
    groupIds: [7, 54, 55, 71, 75],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Legion,
    groupIds: [128, 166, 182, 183, 184, 185, 194, 195, 196, 218, 233],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Heist,
    groupIds: [4, 44, 76, 81, 98],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Ritual,
    groupIds: [10, 16, 36, 38, 80],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Harbinger,
    groupIds: [123, 155, 168],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Breach,
    groupIds: [
      144,
      165,
      167,
      175,
      206,
      208,
      210,
      211,
      215,
      216,
      217,
      220,
      222,
      224,
      225,
      226,
      227,
      229,
      230,
      232,
      234,
    ],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Abyss,
    groupIds: [
      113,
      114,
      116,
      119,
      122,
      126,
      127,
      130,
      132,
      135,
      136,
      139,
      141,
      159,
      163,
      187,
      205,
      207,
    ],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Expedition,
    groupIds: [124, 161, 180, 193, 223],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.Harvest,
    groupIds: [103, 145, 169, 190, 221, 231, 235, 236, 237, 238],
  },
  {
    grouping: ClusterGrouping.LeagueMechanic,
    type: LeagueMechanic.ExcludedLeagueContent,
    groupIds: [78, 146],
  },
  // Master missions
  {
    grouping: ClusterGrouping.MasterMission,
    type: MasterMission.SpawnWeighting,
    groupIds: [100],
  },
  {
    grouping: ClusterGrouping.MasterMission,
    type: MasterMission.Kirac,
    groupIds: [28, 108, 197],
  },
  {
    grouping: ClusterGrouping.MasterMission,
    type: MasterMission.Betrayal,
    groupIds: [148, 188, 202, 228],
  },
  {
    grouping: ClusterGrouping.MasterMission,
    type: MasterMission.Bestiary,
    groupIds: [3, 20, 32, 77],
  },
  {
    grouping: ClusterGrouping.MasterMission,
    type: MasterMission.Delve,
    groupIds: [134, 137, 147, 192],
  },
  {
    grouping: ClusterGrouping.MasterMission,
    type: MasterMission.Incursion,
    groupIds: [31, 79, 84, 85],
  },
  // Map features
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.Scarabs,
    groupIds: [57],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.Sextants,
    groupIds: [176],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.Strongboxes,
    groupIds: [115, 153, 160],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.Shrines,
    groupIds: [117, 142, 143],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.TormentedSpirits,
    groupIds: [5, 67, 91],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.RogueExiles,
    groupIds: [56, 60, 105],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.Maps,
    groupIds: [47, 102, 107, 173],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.VaalSideAreas,
    groupIds: [152, 177],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.Essences,
    groupIds: [27, 65, 92, 99],
  },
  {
    grouping: ClusterGrouping.MapFeature,
    type: MapFeature.Labyrinth,
    groupIds: [70],
  },
  // Bosses
  {
    grouping: ClusterGrouping.Boss,
    type: Boss.Synthesis,
    groupIds: [53, 61],
  },
  {
    grouping: ClusterGrouping.Boss,
    type: Boss.ConquerorsAndSirus,
    groupIds: [164],
  },
  {
    grouping: ClusterGrouping.Boss,
    type: Boss.ShaperAndElder,
    groupIds: [110],
  },
  {
    grouping: ClusterGrouping.Boss,
    type: Boss.Maven,
    groupIds: [112],
  },
  {
    grouping: ClusterGrouping.Boss,
    type: Boss.EaterOfWorlds,
    groupIds: [198, 199, 203, 204],
  },
  {
    grouping: ClusterGrouping.Boss,
    type: Boss.SearingExarch,
    groupIds: [14, 17, 21, 23, 24, 29],
  },
];

//   {
//     grouping: ClusterGrouping.X,
//     type: Y.Z,
//     groupIds: []],
//   },

export const getAllClusterMappings = () => clusterMappings;

export const getClusterMapping = (groupId: number): clusterMapping => {
  const mapping = clusterMappings.find((clusterMapping) =>
    clusterMapping.groupIds.includes(groupId),
  );

  if (!mapping) {
    throw new Error(`Cluster mapping not found for group ID ${groupId}`);
  }

  return mapping;
};
