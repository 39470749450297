import { chartColors } from "charts/colors";
import SingleValueChart from "charts/SingleValueChart";
import { BuildStatsProps } from "./BuildStats";
import { TravelNodeGrouping } from "../tree-helpers/poe-types";
import { AtlasTree, NodeGrouping } from "tree-helpers/types";
import { namedNodeAllocated } from "tree-helpers/node-lookups";

const getSmallNodeEffectMultiplier = (build: AtlasTree) => {
  if (namedNodeAllocated(build, "Grand Design")) {
    return 0;
  }

  if (namedNodeAllocated(build, "Wandering Path")) {
    return 2;
  }

  return 1;
};

const CorePointsPanel = ({ build }: BuildStatsProps) => {
  const travelNodeBonuses = {
    adjacentMapDropChance: 0,
    mapDropDuplicationChance: 0,
    increasedItemQuantity: 0,
    increasedItemRarity: 0,
    increasedEffectOfMods: 0,
  };

  const travelNodeCounts = {
    adjacentMapDropChance: 0,
    mapDropDuplicationChance: 0,
    increasedItemQuantity: 0,
    increasedEffectOfMods: 0,
  };

  const travelNodeTotalsOnTree = {
    adjacentMapDropChance: 35,
    mapDropDuplicationChance: 37,
    increasedItemQuantity: 15,
    increasedEffectOfMods: 15,
  };

  const smallNodeEffectMultiplier = getSmallNodeEffectMultiplier(build);
  const maxBuildPoints = 132;

  build.nodes
    .filter((node) => node.grouping === NodeGrouping.TravelSmallPassive)
    .forEach((node) => {
      switch (node.name as TravelNodeGrouping) {
        case TravelNodeGrouping.AdjacentMapDropChance:
          travelNodeBonuses.adjacentMapDropChance +=
            2 * smallNodeEffectMultiplier;
          travelNodeCounts.adjacentMapDropChance++;
          break;
        case TravelNodeGrouping.MapDropDuplication:
          travelNodeBonuses.mapDropDuplicationChance +=
            0.5 * smallNodeEffectMultiplier;
          travelNodeCounts.mapDropDuplicationChance++;
          break;
        case TravelNodeGrouping.ItemQuantity:
          travelNodeBonuses.increasedItemQuantity +=
            1 * smallNodeEffectMultiplier;
          travelNodeCounts.increasedItemQuantity++;
          break;
        case TravelNodeGrouping.ItemQuantityAndRarity:
          travelNodeBonuses.increasedItemQuantity +=
            1 * smallNodeEffectMultiplier;
          travelNodeBonuses.increasedItemRarity +=
            5 * smallNodeEffectMultiplier;
          travelNodeCounts.increasedItemQuantity++;
          break;
        case TravelNodeGrouping.MapModifierEffect:
          travelNodeBonuses.increasedEffectOfMods +=
            2 * smallNodeEffectMultiplier;
          travelNodeCounts.increasedEffectOfMods++;
      }
    });

  return (
    <div className="flex flex-col w-full self-center">
      <div className="flex flex-row flex-1">
        <div className="flex flex-col justify-center">
          <div className="text-center text-3xl text-blue-200 pb-2 whitespace-nowrap">
            Points allocated
          </div>
          <div>
            <SingleValueChart
              color={chartColors.green.light}
              background={chartColors.gray.dark}
              value={build.allocatedPoints}
              outOf={maxBuildPoints}
              lengthAngle={180}
              labelOffsets={[-8, 0]}
            ></SingleValueChart>
          </div>
          <div className="font-semibold text-gray-400 text-center text-xl -mt-20">
            Out of {maxBuildPoints}
          </div>
        </div>
        <div className="flex-col w-full"></div>
      </div>
      <div className="text-center text-2xl text-blue-200 mt-4">
        Core travel bonuses
      </div>
      <div className="flex flex-row text-base">
        <div className="flex-col">
          <SingleValueChart
            color={chartColors.pink.light}
            background={chartColors.gray.dark}
            value={
              smallNodeEffectMultiplier === 0
                ? 0
                : travelNodeCounts.adjacentMapDropChance
            }
            outOf={travelNodeTotalsOnTree.adjacentMapDropChance}
            label={`${travelNodeBonuses.adjacentMapDropChance}%`}
            startAngle={90}
          ></SingleValueChart>
          <div className="h-8 text-xl whitespace-pre text-center">
            Adjacent map
            <br />
            <div className="-mt-0.5 text-base">drop chance</div>
          </div>
        </div>
        <div className="flex-col">
          <SingleValueChart
            color={chartColors.pink.light}
            background={chartColors.gray.dark}
            value={
              smallNodeEffectMultiplier === 0
                ? 0
                : travelNodeCounts.mapDropDuplicationChance
            }
            outOf={travelNodeTotalsOnTree.mapDropDuplicationChance}
            label={`${travelNodeBonuses.mapDropDuplicationChance}%`}
            startAngle={90}
          ></SingleValueChart>
          <div className="h-8 text-xl whitespace-pre text-center">
            Duplicate map
            <br />
            <div className="-mt-0.5 text-base">drop chance</div>
          </div>
        </div>
        <div className="flex-col">
          <SingleValueChart
            color={chartColors.pink.light}
            background={chartColors.gray.dark}
            value={
              smallNodeEffectMultiplier === 0
                ? 0
                : travelNodeCounts.increasedEffectOfMods
            }
            outOf={travelNodeTotalsOnTree.increasedEffectOfMods}
            label={`+${travelNodeBonuses.increasedEffectOfMods}%`}
            startAngle={90}
          ></SingleValueChart>
          <div className="h-8 text-xl text-center">
            Effect of modifiers
            <br />
            <div className="-mt-0.5 text-base">(non-unique maps)</div>
          </div>
        </div>
        <div className="flex-col">
          <SingleValueChart
            color={chartColors.pink.light}
            background={chartColors.gray.dark}
            value={
              smallNodeEffectMultiplier === 0
                ? 0
                : travelNodeCounts.increasedItemQuantity
            }
            outOf={travelNodeTotalsOnTree.increasedItemQuantity}
            label={`+${travelNodeBonuses.increasedItemQuantity}%`}
            startAngle={90}
          ></SingleValueChart>
          <div className="h-8 text-xl whitespace-pre text-center">
            Increased
            <br />
            Item Quantity
          </div>
        </div>
      </div>
    </div>
  );
};

export default CorePointsPanel;
