export enum LeagueMechanic {
  ExcludedLeagueContent = "Spawn-proofed",
  Beyond = "Beyond",
  Harbinger = "Harbinger",
  Harvest = "Harvest",
  Heist = "Heist",
  Expedition = "Expedition",
  Ritual = "Ritual",
  Blight = "Blight",
  Breach = "Breach",
  Legion = "Legion",
  Abyss = "Abyss",
  Delirium = "Delirium",
  Metamorph = "Metamorph",
}

export enum MasterMission {
  SpawnWeighting = "Spawn weight",
  Kirac = "Kirac",
  Delve = "Delve",
  Bestiary = "Bestiary",
  Betrayal = "Betrayal",
  Incursion = "Incursion",
}

export enum MapFeature {
  Scarabs = "Scarabs",
  Sextants = "Sextants",
  Essences = "Essences",
  RogueExiles = "Rogue exiles",
  VaalSideAreas = "Vaal zones",
  Labyrinth = "Labyrinth",
  Strongboxes = "Strongboxes",
  Shrines = "Shrines",
  TormentedSpirits = "Tormented spirits",
  Maps = "Maps",
}

export enum Boss {
  ConquerorsAndSirus = "Conquerors",
  Synthesis = "Synthesis",
  ShaperAndElder = "Shaper & Elder",
  Maven = "The Maven",
  SearingExarch = "Searing Exarch",
  EaterOfWorlds = "Eater of Worlds",
}

export enum TravelNodeGrouping {
  AdjacentMapDropChance = "Adjacent Map Drop Chance",
  MapDropDuplication = "Map Drop Duplication",
  MapModifierEffect = "Map Modifier Effect",
  ItemQuantity = "Item Quantity",
  ItemQuantityAndRarity = "Item Quantity and Rarity",
}

export enum UberBossKeystoneNodeGrouping {
  UberSirus = "The Perfect Storm",
  UberVenarius = "Memento Mori",
  UberUberElder = "Cosmic Wounds",
  UberMaven = "Throw the Gauntlet",
  UberSearingExarch = "Thirst for Knowledge",
  UberEaterOfWorlds = "Insatiable Appetite",
}
