export const chartColors = {
  red: { light: "#F87171", dark: "#DC2626" },
  yellow: { light: "#FBBF24", dark: "#D97706" },
  green: { light: "#34D399", dark: "#059669" },
  blue: { light: "#60A5FA", dark: "#2563EB" },
  indigo: { light: "#818CF8", dark: "#4F46E5" },
  purple: { light: "#A78BFA", dark: "#7C3AED" },
  pink: { light: "#F472B6", dark: "#DB2777" },
  gray: { light: "#9CA3AF", dark: "#4B5563" },
};

export const chartColorOrder = [
  "red",
  "indigo",
  "green",
  "yellow",
  "purple",
  "blue",
  "pink",
  "gray",
];

export const getSliceColorByIndex = (idx: number) => {
  return chartColors[chartColorOrder[idx % chartColorOrder.length]].dark;
};

export const getLabelColorByIndex = (idx: number) => {
  return chartColors[chartColorOrder[idx % chartColorOrder.length]].light;
};
