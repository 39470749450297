import { useParams } from "react-router-dom";

import { parseTreeUrlOrSlug } from "tree-helpers/parser";

import Navbar from "components/Navbar";
import BuildStats from "components/BuildStats";

const Build = () => {
  const { build: buildSlug } = useParams();
  const { tree: atlasTree, ok, errorMessage: parseError } = parseTreeUrlOrSlug(
    buildSlug || "",
  );

  return (
    <>
      <Navbar
        viewingBuild={ok}
        buildLink={window.location.href}
        buildSlug={buildSlug}
      ></Navbar>
      <div className="flex flex-col">
        {!ok || !atlasTree ? (
          <div className="w-1/3 self-center flex flex-col mt-16 text-gray-300 p-4 rounded-xl bg-gray-200  bg-opacity-10">
            <p className="text-5xl font-mono mb-8 text-red-600">Bad build :(</p>
            <p className="text-3xl mb-4">
              This build is clearly bad, please consider trying another.
            </p>
            <p className="italic leading-none text-base mt-2 text-gray-300">
              {"seriously though, I can't parse this"}
            </p>
            <p className="italic leading-none text-base mt-2 text-gray-400">
              {`(error: ${parseError})`}
            </p>
          </div>
        ) : (
          <BuildStats build={atlasTree}></BuildStats>
        )}
      </div>
    </>
  );
};

export default Build;
