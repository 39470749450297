import { useState } from "react";
import { useNavigate } from "react-router-dom";

import { normalizeTreeSlug, parseTreeUrlOrSlug } from "tree-helpers/parser";
import Navbar from "components/Navbar";

const Home = () => {
  const navigate = useNavigate();
  const [invalidUrl, setInvalidUrl] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>();

  const checkBuildUrlOrSlug = (urlOrSlug: string) => {
    if (urlOrSlug === "") {
      setInvalidUrl(false);
      return;
    }
    const { ok, errorMessage: err } = parseTreeUrlOrSlug(urlOrSlug);
    setErrorMessage(err);
    if (ok) {
      navigate(normalizeTreeSlug(urlOrSlug));
    } else {
      setInvalidUrl(true);
    }
  };

  return (
    <>
      <Navbar></Navbar>
      <div className="w-full h-full flex flex-col">
        <div className="w-2/3 2xl:w-1/3 self-center align-middle flex flex-col mt-36">
          <input
            className={[
              "py-4 text-4xl font-semibold rounded-md text-center placeholder-blue-200",
              invalidUrl
                ? "border-red-400 border-2 bg-red-200 bg-opacity-60  text-red-800"
                : "border-gray-300 border-opacity-25 border-2 bg-white bg-opacity-10",
            ].join(" ")}
            placeholder="Paste your atlas tree link here!"
            tabIndex={1}
            onChange={(event) => checkBuildUrlOrSlug(event.target.value)}
          ></input>
          {invalidUrl && (
            <>
              <div className="mt-4 text-2xl text-gray-300 text-light whitespace-pre">
                {
                  "That doesn't look right - did you paste an atlas tree URL?\n(if you don't have one, try that button up there!)"
                }
              </div>
              <div className="text-sm mt-8 text-gray-400">
                Error: <span className="italic">{errorMessage}</span>
              </div>
            </>
          )}
        </div>

        <div className="w-2/3 2xl:w-1/3 mt-36 self-center space-y-4">
          <div className="font-bold text-3xl">What does this do?</div>
          <div>
            This app displays a{" "}
            <span className="font-semibold text-blue-200">
              visual point breakdown of your atlas tree
            </span>
            , indicating how your points are spent. There are a few different
            chart types, reflecting the core bonuses you&apos;re getting for
            travel nodes, the various node types you invested into and your most
            prioritized game mechanics.
          </div>
          <div className="font-bold text-3xl pt-4">Why?</div>
          <div>
            With the amount of stuff on the atlas tree, it can be daunting to
            look at nodes when you just want{" "}
            <span className="font-semibold text-blue-200">
              a concise representation of which mechanics an atlas tree invests
              into
            </span>{" "}
            without delving deep into the actual point allocation. There are
            enough planners out there which break down the exact stats you get,
            but they all require you to look at a tree for more than a few
            seconds.
          </div>
          <div className="font-bold text-3xl pt-4">
            How should I use this website?
          </div>
          <div>
            Since this website isn&apos;t a fully-fledged atlas tree planner, it
            is the most useful when you use it to examine an atlas tree before
            you see it in a traditional planner. As such, your best way of using
            this website is
            <span className="font-semibold text-blue-200">
              {" "}
              to share your own atlas trees with other people
            </span>
            . You can do so by clicking the{" "}
            <span className="italic font-semibold text-gray-200">
              Copy sharing link
            </span>{" "}
            button when viewing a build.
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
