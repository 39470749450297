import { AtlasTree } from "tree-helpers/types";
import {
  Breakdown,
  ClusterGroupingPredicate,
  NodePredicate,
  NameKey,
} from "./types";

export const buildBreakdown = (
  tree: AtlasTree,
  displayName: string,
  groupingNameKey: NameKey,
  predicates?: { node?: NodePredicate; cluster?: ClusterGroupingPredicate },
  groupingDisplayNameKey?: NameKey,
): Breakdown => {
  const breakdown: Breakdown = {
    displayName,
    groupings: {},
    totalPoints: 0,
  };

  const actualDisplayNameKey = groupingDisplayNameKey ?? groupingNameKey;

  const predicatesSpecified = predicates !== undefined;
  const nodePredicateGiven =
    predicatesSpecified && predicates?.node !== undefined;
  const clusterPredicateGiven =
    predicatesSpecified && predicates?.cluster !== undefined;

  tree.nodes.forEach((node) => {
    if (
      (predicatesSpecified && !nodePredicateGiven) ||
      (nodePredicateGiven && !(predicates?.node || (() => true))(node))
    ) {
      return;
    }

    const nodeNameKey = groupingNameKey(node);
    let existingNodeGrouping = breakdown.groupings[nodeNameKey];

    if (existingNodeGrouping === undefined) {
      existingNodeGrouping = {
        displayName: actualDisplayNameKey(node),
        points: 0,
      };
      breakdown.groupings[nodeNameKey] = existingNodeGrouping;
    }

    existingNodeGrouping.points++;
    breakdown.totalPoints++;
  });

  tree.nodes
    .filter((node) => node.cluster !== undefined)
    .forEach((node) => {
      if (
        !clusterPredicateGiven ||
        (node.cluster && !(predicates?.cluster || (() => true))(node.cluster))
      ) {
        return;
      }

      const clusterNameKey = groupingNameKey(undefined, node.cluster);
      let existingClusterGrouping = breakdown.groupings[clusterNameKey];

      if (existingClusterGrouping === undefined) {
        existingClusterGrouping = {
          displayName: actualDisplayNameKey(undefined, node.cluster),
          points: 0,
        };
        breakdown.groupings[clusterNameKey] = existingClusterGrouping;
      }

      existingClusterGrouping.points++;
      breakdown.totalPoints++;
    });

  return breakdown;
};
