import { PieChart } from "react-minimal-pie-chart";
import { SingleValueChartProps } from "./types";

const SingleValueChart = (props: SingleValueChartProps) => {
  return (
    <PieChart
      data={[{ value: props.value, color: props.color, title: props.value }]}
      totalValue={props.outOf}
      lengthAngle={props.lengthAngle}
      startAngle={props.startAngle ?? 180}
      rounded
      animate
      animationDuration={800}
      background={props.background}
      lineWidth={props.lineWidth ?? 20}
      radius={props.radius ?? 44}
      label={props.label ? () => props.label : () => props.value.toFixed(0)}
      labelPosition={0}
      labelStyle={{
        fill: props.labelColor ?? props.color,
        transform: props.labelOffsets
          ? `translateX(${props.labelOffsets[1]}px) translateY(${props.labelOffsets[0]}px)`
          : undefined,
      }}
    ></PieChart>
  );
};

export default SingleValueChart;
