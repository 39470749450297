import { Link } from "react-router-dom";
import { CopyToClipboard } from "react-copy-to-clipboard";

import poeLogo from "../assets/poe-ancestor.png";

type NavbarProps = {
  viewingBuild?: boolean;
  buildLink?: string;
  buildSlug?: string;
};

const Navbar = (props: NavbarProps) => {
  return (
    <div className="w-full flex flex-row items-center justify-evenly space-x-8">
      <div className="flex-1 self-center text-center space-x-4 flex justify-end items-stretch">
        {props.viewingBuild && props.buildLink && props.buildSlug ? (
          <>
            <a
              href={`https://www.pathofexile.com/fullscreen-atlas-skill-tree/${props.buildSlug}`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="p-4 h-full text-base font-semibold bg-indigo-900 text-gray-300 rounded-lg">
                View actual tree <br />
                <span className="font-light">(pathofexile.com)</span>
              </button>
            </a>
            <a
              href={`https://www.poeplanner.com/atlas-tree/${props.buildSlug}`}
              target="_blank"
              rel="noreferrer"
            >
              <button className="p-4 h-full text-lg leading-snug font-semibold bg-blue-800 text-gray-300 rounded-lg">
                Edit tree <br />
                <span className="font-light">(poeplanner.com)</span>
              </button>
            </a>
            <CopyToClipboard text={props.buildLink}>
              <button className="p-4 text-xl font-bold bg-green-600 text-gray-100 rounded-lg">
                Copy sharing link!
              </button>
            </CopyToClipboard>
          </>
        ) : (
          <>
            <a
              href="https://www.pathofexile.com/fullscreen-atlas-skill-tree"
              target="_blank"
              rel="noreferrer"
            >
              <button className="p-4 text-xl font-semibold bg-gray-700 bg-opacity-80 text-gray-200 rounded-lg">
                Plan new tree
                <br />
                <span className="font-light">(pathofexile.com)</span>
              </button>
            </a>
            <a
              href="https://www.poeplanner.com/atlas-tree"
              target="_blank"
              rel="noreferrer"
            >
              <button className="p-4 font-semibold bg-blue-800 bg-opacity-80 text-gray-200 rounded-lg">
                Plan new tree
                <br />
                <span className="font-light">(poeplanner.com)</span>
              </button>
            </a>
          </>
        )}
      </div>

      <div className="flex-1 flex-shrink-0 min-w-min">
        <Link to="/">
          <div className="flex justify-center items-center">
            <div className="flex-1"></div>
            <div className="h-60 flex-1 flex items-stretch justify-center">
              <img src={poeLogo} className="max-h-full self-center"></img>
            </div>
            <div className="flex-1">
              <div className="text-5xl text-gray-200 italic max-w-min ml-4">
                Atlas tree breakdown
              </div>
            </div>
          </div>
        </Link>
      </div>
      <div className="text-md italic leading-none font-mono font-light text-gray-300 flex-1">
        Built with ❤ by /u/IcyRespawn
        <br />
        <span className="text-sm text-gray-400">@icyrespawn on Discord</span>
        <span className="text-sm text-gray-300 mx-4">•</span>
        <span className="text-sm text-gray-400">
          Check out my real side-project,{" "}
          <a
            className="text-gray-400 underline"
            href="https://deej.rocks/"
            target="_"
          >
            deej
          </a>
          !
        </span>
      </div>
    </div>
  );
};

export default Navbar;
