import { Breakdown } from "node-groupings/types";
import {
  DataEntry,
  LabelRenderFunction,
} from "react-minimal-pie-chart/types/commonTypes";
import { getSliceColorByIndex, getLabelColorByIndex } from "./colors";

export interface PieChartProps {
  breakdown: Breakdown;
  customLabel?: LabelRenderFunction;
  radius?: number;
  shift?: boolean;
}

export interface SingleValueChartProps {
  value: number;
  color: string;
  outOf?: number;
  radius?: number;
  lineWidth?: number;
  startAngle?: number;
  lengthAngle?: number;
  background?: string;
  label?: string;
  labelColor?: string;
  labelOffsets?: [number, number];
}

export const getChartData = (breakdown: Breakdown): DataEntry[] => {
  const allGroupings = Object.values(breakdown.groupings);
  const groupingsWithOrder = allGroupings.filter(
    (grouping) => grouping.order !== undefined,
  );
  const groupingsWithoutOrder = allGroupings.filter(
    (grouping) => grouping.order === undefined,
  );

  return groupingsWithOrder
    .sort((a, b) => (a.order || 0) - (b.order || 0))
    .concat(groupingsWithoutOrder.sort((a, b) => b.points - a.points))
    .map((grouping, idx) => ({
      color: getSliceColorByIndex(idx),
      labelColor: getLabelColorByIndex(idx),
      value: grouping.points,
      title: grouping.displayName,
    }));
};
