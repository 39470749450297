import { AtlasTree, Cluster, NodeGrouping, NodeType, TreeNode } from "./types";
import treeData from "../skill-tree-data/data.json";
import { TravelNodeGrouping, UberBossKeystoneNodeGrouping } from "./poe-types";
import { getClusterMapping } from "./cluster-mappings";

export interface nodeJSONFormat {
  name: string;
  group: number;
  stats?: string[];
  isNotable?: boolean;
  isMastery?: boolean;
  isKeystone?: boolean;
  isWormhole?: boolean;
}

const treeNodeFromNodeData = (id: number, data: nodeJSONFormat): TreeNode => {
  const grouping = getNodeGroupingFromNodeData(data);
  return {
    id,
    name: data.name ?? "n/a",
    type: getNodeTypeFromNodeData(data),
    grouping: grouping,
    stats: data.stats ?? [],
    cluster: [
      NodeGrouping.Notable,
      NodeGrouping.UberBossKeystone,
      NodeGrouping.ClusterSmallPassive,
    ].includes(grouping)
      ? getClusterByGroupId(data.group)
      : undefined,
  };
};

const getNodeTypeFromNodeData = (data: nodeJSONFormat): NodeType => {
  if (data.isKeystone) {
    return NodeType.Keystone;
  }

  if (data.isNotable) {
    return NodeType.Notable;
  }

  if (data.isWormhole) {
    return NodeType.Wormhole;
  }

  return NodeType.SmallPassive;
};

export const getNodeGroupingFromNodeData = (
  data: nodeJSONFormat,
): NodeGrouping => {
  if ((Object.values(TravelNodeGrouping) as string[]).includes(data.name)) {
    return NodeGrouping.TravelSmallPassive;
  }

  if (data.isKeystone) {
    if (
      (Object.values(UberBossKeystoneNodeGrouping) as string[]).includes(
        data.name,
      )
    ) {
      return NodeGrouping.UberBossKeystone;
    }

    return NodeGrouping.MappingKeystone;
  }

  if (data.isNotable) {
    return NodeGrouping.Notable;
  }

  if (data.isWormhole) {
    return NodeGrouping.Gateway;
  }

  return NodeGrouping.ClusterSmallPassive;
};

const getClusterByGroupId = (id: number): Cluster => {
  const clusterMapping = getClusterMapping(id);
  return {
    id,
    name: clusterMapping.type,
    grouping: clusterMapping.grouping,
    type: clusterMapping.type,
  };
};

export const getNodeById = (id: number): TreeNode => {
  const nodeData = treeData.nodes[id.toString()];

  if (nodeData === undefined) {
    throw new Error(`No known passive node with ID ${id}`);
  }

  return treeNodeFromNodeData(id, nodeData);
};

export const namedNodeAllocated = (
  tree: AtlasTree,
  nodeName: string,
): boolean => {
  return tree.nodes.find((node) => node.name === nodeName) !== undefined;
};
